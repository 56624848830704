import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6be85045"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "project_list"
};
const _hoisted_2 = {
  class: "type_title"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "second_type"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 0,
  class: "ranking_list"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "cover"
};
const _hoisted_9 = ["src", "fit"];
const _hoisted_10 = {
  class: "info"
};
const _hoisted_11 = {
  class: "title"
};
const _hoisted_12 = {
  key: 0,
  class: "official"
};
const _hoisted_13 = {
  class: "price"
};
const _hoisted_14 = {
  class: "cover"
};
const _hoisted_15 = ["src", "fit"];
const _hoisted_16 = {
  class: "info"
};
const _hoisted_17 = {
  class: "title"
};
const _hoisted_18 = {
  class: "zc_info"
};
const _hoisted_19 = {
  class: "shop_item_bottom_money"
};
const _hoisted_20 = {
  class: "shop_item_bottom_people"
};
const _hoisted_21 = {
  key: 1,
  class: "shop_item_bottom_bullish_count"
};
const _hoisted_22 = {
  class: "cover"
};
const _hoisted_23 = ["src", "fit"];
const _hoisted_24 = {
  class: "info"
};
const _hoisted_25 = {
  class: "title render-line-clamp"
};
const _hoisted_26 = {
  class: "idea_info"
};
const _hoisted_27 = {
  key: 0
};
import Error from "@@/common/Error.vue";
import MDRouter from "@/service/router";
import navLoading from '@@/rank/loading.vue';
import { List, showFailToast } from 'vant';
import { getRankCategoryList, rankList, detailsV2, ideaRankingList } from "@api/index";
import { reactive, computed, onMounted } from 'vue';
import env from "md-base-tools/env";
export default {
  setup(__props) {
    const isShow = computed(() => env.isModianAndroid() || env.isModianIos() || env.isModianIosNew());
    const state = reactive({
      category: '',
      type: '',
      category_list: [],
      sub_category: [],
      rank_index: 0,
      loading: true,
      finished: false,
      fit: 'cover',
      list: [],
      page: 1,
      postCount: 0,
      default_img: 'https://s.moimg.net/m/img/placeholder/default_1x1@2x.png'
    });
    onMounted(() => {
      window.userHeader.titleText("排行榜");
      resetHeight(["#app", ".cont-warp"]);
    });
    getRankCategoryList({}).then(res => {
      if (res.status == 0 && res.data) {
        state.category_list.push(...res.data);
        state.category_list.forEach(item => {
          if (item.def == 1) {
            state.type = item.type;
            state.category = item.sub_category[0].id;
            console.log('state.category：' + state.category);
            getList();
            state.sub_category.push(...item.sub_category);
            state.sub_category.forEach(e => {
              if (e.def == 1) {
                state.category = e.id;
              }
            });
          }
        });
      } else {
        showFailToast(res.message);
      }
    });
    const changeCategoryType = item => {
      if (state.loading) {
        return false;
      }
      state.type = item.type;
      state.sub_category = [];
      state.sub_category.push(...item.sub_category);
      if (state.type == 'rank') {
        state.category = state.sub_category[state.rank_index].val;
      } else {
        state.category = state.sub_category[state.rank_index].id;
      }
      state.finished = false;
      state.page = 1;
      state.postCount = 0;
      getList();
    };
    const changerankType = (item, index) => {
      if (state.loading) {
        return false;
      }
      state.rank_index = index;
      if (state.type == 'rank') {
        state.category = item.val;
      } else {
        state.category = item.id;
      }
      state.finished = false;
      state.page = 1;
      state.postCount = 0;
      getList();
    };
    // 翻页
    const onLoad = () => {
      console.log('onload');
      if (state.page == 1) {
        return false;
      }
      getList();
    };
    // 下拉刷新
    // const onRefresh = () => {
    //     state.finished = false;
    //     // 将 loading 设置为 true，表示处于加载状态
    //     state.loading = true;
    //     onLoad();
    // };
    const getRankList = (option, callback) => {
      option.category = state.category;
      option.type = state.type;
      rankList(option).then(res => {
        callback.call(this, res);
      });
    };
    const getShopList = (option, callback) => {
      option.category = state.type;
      option.rank = state.category;
      detailsV2(option).then(res => {
        callback.call(this, res);
      });
    };
    const ideaRankList = (option, callback) => {
      option.category = state.type;
      option.type = state.category;
      ideaRankingList(option).then(res => {
        callback.call(this, res);
      });
    };
    const ResCallback = res => {
      if (res.status == 0) {
        if (state.page == 1) {
          state.list.splice(0, state.list.length);
        }
        setTimeout(() => {
          if (res.data.list && res.data.list.length > 0) {
            state.list.push(...res.data.list);
          }
          // 加载状态结束
          state.loading = false;
          state.page++;
        }, 10);
        // 数据全部加载完成
        if (!res.data.is_next || res.data.list.length == 0) {
          state.finished = true;
        }
      } else {
        showFailToast(res.message);
      }
    };
    const getList = () => {
      state.postCount++;
      state.loading = true;
      if (state.page == 1 && state.postCount > 1) {
        return false;
      }
      let option = {
        page: state.page,
        page_size: 10,
        request_id: ''
      };
      if (state.type == 'rank') {
        getShopList(option, res => {
          ResCallback(res);
        });
      } else if (state.type == '99') {
        getRankList(option, res => {
          ResCallback(res);
        });
      } else {
        ideaRankList(option, res => {
          ResCallback(res);
        });
      }
    };
    // 去详情
    const goToInfo = item => {
      if (state.type == 'rank') {
        MDRouter.goMallInfo(item.product_id);
      } else if (state.type == '0') {
        MDRouter.goIdeaInfo(item.idea_id);
      } else {
        MDRouter.goProInfo(item.id);
      }
    };
    const resetHeight = elmArr => {
      elmArr.forEach(item => {
        var type = item.slice(0, 1),
          elm = item.slice(1);
        switch (type) {
          case '#':
            document.getElementById(elm).style.height = '100%';
            break;
          case '.':
            document.getElementsByClassName(elm)[0].style.height = '100%';
            break;
          default:
            document.getElementsByTagName(item)[0].style.height = '100%';
        }
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass(['top_type', {
          'top': _unref(isShow)
        }])
      }, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).category_list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass({
            'active': _unref(state).type == item.type
          }),
          onClick: _withModifiers($event => changeCategoryType(item), ["stop"])
        }, _toDisplayString(item.category_name), 11, _hoisted_3);
      }), 128))]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).sub_category, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass({
            'active': _unref(state).category == item.id || _unref(state).category == item.val
          }),
          onClick: _withModifiers($event => changerankType(item, index), ["stop"])
        }, _toDisplayString(item.title), 11, _hoisted_5);
      }), 128))])], 2), _createVNode(_unref(List), {
        loading: _unref(state).loading,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _unref(state).loading = $event),
        finished: _unref(state).finished,
        "immediate-check": "false",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(state).list.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).list, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            onClick: $event => goToInfo(item),
            class: _normalizeClass({
              'idea': _unref(state).type == '0'
            })
          }, [_unref(state).type == 'rank' ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
            src: _ctx.$imgUrlFilter(item.img_url, 240, 240) || _unref(state).default_img,
            fit: _unref(state).fit,
            onerror: "javascript:this.src='https://s.moimg.net/m/img/placeholder/default_1x1@2x.png'"
          }, null, 8, _hoisted_9)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("p", _hoisted_11, [_createElementVNode("span", {
            class: _normalizeClass(['gray', {
              'gold': index < 3
            }])
          }, _toDisplayString(index + 1), 3), item.official == 1 ? (_openBlock(), _createElementBlock("i", _hoisted_12, "自营")) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(item.name), 1)]), _createElementVNode("p", _hoisted_13, "¥" + _toDisplayString(item.price), 1)])], 64)) : _unref(state).type == '99' ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createElementVNode("div", _hoisted_14, [_createElementVNode("img", {
            src: _ctx.$imgUrlFilter(item.wb_logo_1, 240, 240) || _unref(state).default_img,
            fit: _unref(state).fit,
            onerror: "javascript:this.src='https://s.moimg.net/m/img/placeholder/default_1x1@2x.png'"
          }, null, 8, _hoisted_15)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("p", _hoisted_17, [_createElementVNode("span", {
            class: _normalizeClass(['gray', {
              'gold': index < 3
            }])
          }, _toDisplayString(index + 1), 3), _createTextVNode(" " + _toDisplayString(item.name), 1)]), _createElementVNode("div", _hoisted_18, [_unref(state).type == '99' ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createElementVNode("view", _hoisted_19, _toDisplayString(item.backer_money), 1), _createElementVNode("view", _hoisted_20, _toDisplayString(item.backer_count), 1)], 64)) : (_openBlock(), _createElementBlock("view", _hoisted_21, " 看好人数 " + _toDisplayString(item.bullish_count), 1))])])], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
            key: 2
          }, [_createElementVNode("div", _hoisted_22, [_createElementVNode("img", {
            src: _ctx.$imgUrlFilter(item.logo, 240, 240) || _unref(state).default_img,
            fit: _unref(state).fit,
            onerror: "javascript:this.src='https://s.moimg.net/m/img/placeholder/default_1x1@2x.png'"
          }, null, 8, _hoisted_23)]), _createElementVNode("div", _hoisted_24, [_createElementVNode("p", _hoisted_25, [_createElementVNode("span", {
            class: _normalizeClass(['gray', {
              'gold': index < 3
            }])
          }, _toDisplayString(index + 1), 3), _createTextVNode(" " + _toDisplayString(item.title), 1)]), _createElementVNode("p", _hoisted_26, [item.category ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(item.category), 1)) : _createCommentVNode("", true), _createTextVNode("看好人数 " + _toDisplayString(item.favor_count), 1)])])], 64))], 10, _hoisted_7);
        }), 128))])) : _createCommentVNode("", true), _unref(state).loading && _unref(state).page == 1 ? (_openBlock(), _createBlock(navLoading, {
          key: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["loading", "finished"]), !_unref(state).loading && _unref(state).list.length == 0 && _unref(state).loading == false ? (_openBlock(), _createBlock(Error, {
        key: 0,
        message: "什么都没有哦",
        class: "empty-box"
      })) : _createCommentVNode("", true)]);
    };
  }
};